<template>
  <div class="select-tree-container">
    <van-field
      readonly
      required
      clickable
      name="picker"
      :value="kindValue"
      label="培训职业及工种"
      placeholder="请选择"
      @click="showPickers = true"
    />
    <van-popup round v-model="showPickers" position="bottom">
      <div class="option-head">
        <div class="cancel" @click="showPickers = false">取消</div>
        <div class="head-title">请选择职业及工种</div>
        <div class="sure" @click="comfirm">确认</div>
      </div>
      <van-tree-select
        :items="workerList"
        :active-id.sync="activeIds"
        :main-active-index.sync="activeIndex"
        selected-icon="checked"
        @click-item="clickRightItem"
        @click-nav="clickLeftItem"
      />
    </van-popup>
    <p class="line"></p>
  </div>
</template>

<script>
export default {
  props: {
    showPicker: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPickers: this.showPicker,
      workerList: [], // 职业工种列表
      activeIds: [], // 获取已经选择工种
      activeIndex: 0, // 已选择工种左侧职业
      kindValue: "", // 显示工种信息
      workerName: [],
    };
  },
  mounted() {
    this.getWorkType();
  },
  methods: {
    comfirm() {
      this.workerName = [];
      this.$emit("workerCode", this.activeIds.join(","));
      this.workerList.map((item, index) => {
        item.children.map((ite) => {
          this.activeIds.map((active) => {
            if (ite.vocationCode == active) {
              this.workerName.push(`${item.vocationName}-${ite.vocationName}`);
            }
          });
        });
      });
      this.$emit("workerName", this.workerName);
      this.showPickers = false;
    },
    // 获取工种
    getWorkType() {
      this.$api.rensheUsercenter.getAllLevelListNew().then((res) => {
        if (res.success && res.data) {
          res.data.slice(0,res.data.length-1).map((item, index) => {
            item.text = item.vocationName;
            item.id = item.vocationCode;
            item.children = item.subVocations;
            item.children.filter((ite) => {
              if(!['营养指导员','整理收纳师','家务服务员','家庭照护员'].includes(ite.vocationName)){
                ite.text = ite.vocationName;
                ite.id = ite.vocationCode;
              }
            });
            return item;
          });
          this.workerList = res.data.slice(0,res.data.length-1);
        }
      });
    },
    // 点击左侧树形导航
    clickLeftItem(index) {
      let treeDom = document.getElementsByClassName("van-sidebar-item");

        treeDom.forEach((item) => {
          if (item.firstChild.classList.contains("select-style")){
                item.firstChild.style.backgroundColor = "#9BAAF8";
                
          }
          if(treeDom[index].firstChild.classList.contains("select-style")){
            treeDom[index].firstChild.style.backgroundColor = "#fff";
          }
        });

      // 改变点击颜色
    },
    // 点击右侧树形导航
    clickRightItem(data) {
      // data.id  全部转换成data.value
      let span = document.createElement("span");
      span.setAttribute("class", "select-style");
      let treeDom = document.getElementsByClassName("van-sidebar-item")[this.activeIndex];
      if (this.activeIds.includes(data.value)) {
        // data.value
        if (!treeDom.firstChild.classList.contains("select-style")) {
          // 判断是否包含对应的提示icon
          treeDom.prepend(span);
          span.style.backgroundColor = '#fff'
        }
      }
      let hasActive = false;
      this.workerList[this.activeIndex].children.map((item) => {
        this.activeIds.map((ite) => {
          if (ite == item.value) {
            return (hasActive = true);
          }
        });
        return hasActive;
      });
      if (!hasActive) {
        treeDom.firstChild.remove();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.select-tree-container {
  .option-head {
    display: flex;
    justify-content: space-between;
    padding: 35px 30px;
    box-sizing: border-box;
    .cancel {
      font: 400 32px PingFangSC-Regular, PingFang SC;
      color: #999;
    }
    .head-title {
      font: 500 36px PingFangSC-Medium, PingFang SC;
      color: #333;
    }
    .sure {
      font: 400 32px PingFangSC-Regular, PingFang SC;
      color: #3660ff;
    }
  }
  /deep/.van-ellipsis {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /deep/.van-sidebar-item--select::before {
    display: none;
  }
  /deep/.van-tree-select__content {
    flex: 1;
  }

  /deep/.van-icon {
    position: absolute;
    right: 10px;
  }
  /deep/.van-tree-select__item {
    word-break: break-all;
    white-space: normal;
  }
  /deep/.van-sidebar-item {
    padding-left: 30px !important;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .line {
    margin: 0 30px;
    // border-bottom: 1px solid #ebedf0;
    width: calc(100% - 60px);
  }
  /deep/.select-style {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #9baaf8;
    display: inline-block;
    /* 加定位防止乱抖 */
    position: absolute;
  }
  // /deep/.van-sidebar-item__text {
  //   margin-left: 30px;
  // }
}
</style>